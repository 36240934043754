.container {
  display: flex;
  width: 100%;
  /* or inline-flex */
}

.tstamp {
  font-size: xx-small;
  font-weight: 600;
  word-wrap: normal !important;
  word-break: keep-all;
  color: green;
  text-align: end;
}

.sidebarChat {
  display: flex;
  padding-left: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  cursor: pointer;
  border-bottom: 1px solid #f6f6f6;
}

hr.thin {
  height: 1px;
  border: 0;
  color: #e6e7ea;
  background-color: #e6e7ea;
  width: 100%;
  margin-bottom: 5px;
}

.sidebarChat:hover {
  background-color: #ebebeb;
}

.sidebarChat__info>h2 {
  font-size: 16px;
  margin-bottom: 8px;
}

.sidebarChat__info {
  margin-left: 15px;
  width: 100%;
}

.sidebarChatend {
  margin-left: 15px;
}

.c {
  font-size: x-small;
  font-weight: 600;
  word-wrap: normal !important;
  word-break: keep-all;
  margin-bottom: 5px;
}

.cr {
  font-size: x-small;
  font-weight: 600;
  color: blue;
  word-wrap: normal !important;
  word-break: keep-all;
  margin-bottom: 5px;
}

.pn {
  font-size: x-small;
  font-weight: 600;
  word-wrap: normal !important;
  word-break: keep-all;
  margin-bottom: 20px;
}


a {
  text-decoration: none;
  color: black;
}