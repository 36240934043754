.app {
  background-color: #dadbd3;
  /*display: grid;
  place-items: center;*/
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
}

.app__body {
  display: flex;
  background-color: #ededed;
  height: 90vh;
  width: 90vw;
  box-shadow: -1px 4px 20px -6px rgba(0, 0, 0, 0.7);
}

.project__info {
  flex: 0.65;
  display: flex;
  flex-direction: column;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #ededed;
  overflow: scroll;
}

.project__info h1 {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 2.2rem;
  font-weight: 400;
}

.project__info img {
  -o-object-fit: contain;
  object-fit: contain;
  max-width: 300px;
}

@media screen and (max-width: 800px) {
  .app__body {
    height: 100vh;
    width: 100vw;
  }
}

@media screen and (max-width: 630px) {
  .app__body {
    overflow: auto;
  }

  .app__body {
    overflow: auto;
  }

  .project__info {
    display: none;
  }
}